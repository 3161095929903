exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-agency-jsx": () => import("./../../../src/templates/Agency.jsx" /* webpackChunkName: "component---src-templates-agency-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/Contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-news-category-jsx": () => import("./../../../src/templates/NewsCategory.jsx" /* webpackChunkName: "component---src-templates-news-category-jsx" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/News.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */),
  "component---src-templates-page-default-jsx": () => import("./../../../src/templates/PageDefault.jsx" /* webpackChunkName: "component---src-templates-page-default-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/Project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-projects-jsx": () => import("./../../../src/templates/Projects.jsx" /* webpackChunkName: "component---src-templates-projects-jsx" */)
}

