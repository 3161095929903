import React, { useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
// import { LocaleContext } from "../contexts/LocaleWrapper"

const query = graphql`
  query {
    prismicMenu(uid: { eq: "menu-header" }) {
      data {
        menu_items {
          item {
            uid
          }
          title {
            text
          }
        }
      }
    }
  }
`

const Menu = () => {
  const { prismicMenu } = useStaticQuery(query)
  // const { localeCtx } = useContext(LocaleContext)
  // console.log(prismicMenu);
  return (
    <nav id="nav-primary">
      <ul className="flex flex-wrap uppercase">
        {prismicMenu.data.menu_items.map((li, i) => (
          <li key={i} className="menu-item">
            <Link
              to={`/${li.item.uid}`}
              className="pr-xxs md:pr-sm hover:text-white "
            >
              {li.title.text}
            </Link>
          </li>
        ))}
        {/* <li className="menu-item sm-only">
          <Link to="/contacts" className="">
            Kontakt
          </Link>
        </li> */}
      </ul>
    </nav>
  )
}

export default Menu
