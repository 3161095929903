import { Link } from "gatsby"
import React from "react"
import { linkResolver } from "../../core/utils"
// import Slider from "../ui/slick-slider"

const NewsMarquee = ({ input }) => {
  // console.log(input)

  const _truncate = (source, maxWords) => {
    if (!source) return ""
    const explode = source.split(" ")
    let string = explode.splice(0, maxWords).join(" ")
    string += "..."
    // console.log(explode)
    return string
    // const
  }
  // console.log(input.items)
  const link = input.primary.link?.document
    ? linkResolver(input.primary.link?.document)
    : "/news"
  return input.items.length === 0 ? null : (
    <section
      className="slice-news-marquee bg-yellow py-md"
      style={{
        padding: "17px 0 14px",
      }}
    >
      <Link to={link}>
        <marquee>
          <div className="flex">
            {input.items.map(({ item }, i) => (
              <div
                className="slide pr-lg flex items-baseline text-md md:text-lg"
                key={i}
              >
                {item.document && (
                  <>
                    <h3 className="font-strong pr-xs text-md md:text-lg">
                      {item.document?.data.title.text} :
                    </h3>
                    <p>{_truncate(item.document?.data.texte.text, 10)}</p>
                  </>
                )}
              </div>
            ))}
          </div>
        </marquee>
      </Link>
    </section>
  )
}

export default NewsMarquee
