module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"agence-ter-germany","accessToken":"MC5Zb1BFa2hNQUFDTUFid3FV.OBl3Ve-_ve-_vRjvv71W77-977-9Ze-_vTvvv73vv71N77-977-977-977-977-9Z1fvv71677-977-977-9Su-_vVQ","promptForAccessToken":true,"apiEndpoint":"https://agence-ter-germany.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"agence ter","short_name":"Site Alt","start_url":"/","background_color":"#faf796","theme_color":"#faf796","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"23f6b75fa83bf61f0739dcbb4fdbfcfe"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
