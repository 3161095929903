import React, { useContext } from "react"
import { LocaleContext } from "../contexts/LocaleWrapper"
import { RichText } from "prismic-reactjs"
import clsx from "clsx"
import AnimateOnScroll from "./ui/AnimateOnScroll"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const ContactsAgence = ({ input }) => {
  const { localeCtx } = useContext(LocaleContext)
  const { nom, image, infos, liens } = input
  // console.log(liens)
  return (
    <AnimateOnScroll>
      <article className="mb-lg">
        <div className="row">
          <div className="col-md-2 col-xs">
            {image && image.url && (
              <figure className="">
                <a href={liens.url} target="_blank" rel="noopener, noreferrer">
                  <GatsbyImage
                    image={getImage(image)}
                    alt={image.alt || ""}
                    style={{
                      aspectRatio: "1/1",
                    }}
                  />
                </a>
              </figure>
            )}
          </div>
          <div className="col-md-1 hidden-sm"></div>
          <div className="col-xs">
            <div className={clsx("row")}>
              <div className={clsx("col-xs ")}>
                <h2 className="font-bold">{nom.text}</h2>
                <RichText render={infos.richText} />
              </div>
            </div>
          </div>
        </div>
      </article>
    </AnimateOnScroll>
  )
}

export default ContactsAgence
