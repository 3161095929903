//const website = require('./website')

module.exports = {
  de: {
    default: true,
    label: "De",
    path: "de",
    locale: "de",
    siteLanguage: "de",
    ogLang: "de_DE",
    projects: "Projekte",
    about: "About",
    contact: "Kontakt",
    // title: "Projektname",
    title: "PROJEKT",
    theme: "Kategorie",
    themes: "Kategorien",
    year: "JAHR",
    years: "JAHR",
    localisation: "Ort",
    localisations: "Länder",
    superficie: "Fläche",
    status: "Status",
    actualites: "Aktuelles",
    distinctions: "Auszeichnungen",
    filtre: "Filter",
    prevProject: "Vorheriges",
    nextProject: "Nächstes",
    back: "zurück",
    contacts: "Kontakt",
    mentionsLegales: "Impressum",
  },
  // "fr-fr": {
  //   default: false,
  //   label: "Fr",
  //   path: "fr",
  //   locale: "fr-fr",
  //   siteLanguage: "fr",
  //   ogLang: "fr_FR",
  //   projects: "Projets",
  //   about: "À propos",
  //   contact: "Contact",
  //   title: "titre",
  //   theme: "thème",
  //   themes: "thèmes",
  //   year: "année",
  //   years: "années",
  //   localisation: "localisation",
  //   localisations: "localisations",
  //   superficie: "superficie",
  //   status: "statut",
  //   actualites: "actualités",
  //   distinctions: "distinctions",
  //   filtre: "Filtres",
  //   prevProject: "Projet précédent",
  //   nextProject: "Projet suivant",
  //   back: "Retour",
  // },
  // "en-gb": {
  //   default: false,
  //   label: "En",
  //   path: "en",
  //   locale: "en-gb",
  //   siteLanguage: "en",
  //   ogLang: "en_GB",
  //   projects: "Projects",
  //   about: "About",
  //   contact: "Contact",
  //   title: "title",
  //   theme: "theme",
  //   themes: "themes",
  //   year: "year",
  //   years: "years",
  //   localisation: "localisation",
  //   localisations: "localisations",
  //   superficie: "superficie",
  //   status: "status",
  //   actualites: "news",
  //   distinctions: "award",
  //   filtre: "Filters",
  //   prevProject: "Previous projet",
  //   nextProject: "Next projet",
  //   back: "Back",
  // },
}
